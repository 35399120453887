<!-- eslint-disable max-len -->
<!-- eslint-disable max-len -->
<!-- eslint-disable max-len -->
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class MyFooter extends Vue {}
</script>

<template>
  <footer class="container mx-auto relative">
    <div class="flex flex-col">
      <img src="../assets/footer.jpeg" alt="" class="min-w-full md:hidden" />
      <img
        src="../assets/footer-ls.jpeg"
        alt=""
        class="min-w-full hidden md:block"
      />
      <div class="absolute top-6 px-6 w-full">
        <div class="flex flex-row items-center justify-between">
          <div class="text-2xl md:text-4xl text-white font-bold font-cormorant-garamond">
            Morgan Oasis Nails
          </div>
          <div class="flex space-x-2 md:space-x-6">
            <a href="https://www.facebook.com/profile.php?id=100054221360821">
              <img src="../assets/fb.png" class="h-6 md:h-9 mt-1 inverted" alt="" />
            </a>

            <a href="https://www.instagram.com/morganoasisnails_2020/">
              <img src="../assets/instagram.png" class="h-8 md:h-12 inverted" alt="" />
            </a>
          </div>
        </div>
      </div>

      <div
        class="absolute top-16 md:top-22 px-6 text-white text-sm md:text-lg w-screen flex flex-col"
      >
        <!--      <div>Mon-Sat: 9:30 AM - 7:00 PM  &#8226;  Sun: Close</div>-->
        <!--      <div>1719 S Morgan Rd, Oklahoma City, OK 73128</div>-->
        <!--      <div>(405) 265-2494</div>-->
        <div>
          Monday - Saturday: 9:30 AM - 7:00 PM<br />
          Sunday: Close<br />
          (405) 265-2494<br />
          1719 S Morgan Rd, Oklahoma City, OK 73128
        </div>
      </div>

      <div
        class="absolute flex flex-col bottom-6 justify-between w-screen px-6 text-sm"
      >
        <div class="flex flex-col">
          <a href="https://3amlab.io" class="flex flex-row items-center">
            <img src="../assets/3am_logo.png" class="h-8 mr-2" alt="" />
            <div class="flex flex-col items-start text-white text-[12px]">
              <span class="">Powered by</span>
              <span class="text-[12px] font-mono">3AMLAB.IO</span>
            </div>
          </a>

          <div class="flex flex-col">
            <div class="text-white text-sm md:text-lg">
              Copyright &copy; 2022, All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
/* Apply the inverted effect using the filter property */
.inverted {
  filter: invert(100%);
}
</style>
