import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/footer.jpeg'
import _imports_1 from '../assets/footer-ls.jpeg'
import _imports_2 from '../assets/fb.png'
import _imports_3 from '../assets/instagram.png'
import _imports_4 from '../assets/3am_logo.png'


const _hoisted_1 = { class: "container mx-auto relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"flex flex-col\" data-v-bc83a99a><img src=\"" + _imports_0 + "\" alt=\"\" class=\"min-w-full md:hidden\" data-v-bc83a99a><img src=\"" + _imports_1 + "\" alt=\"\" class=\"min-w-full hidden md:block\" data-v-bc83a99a><div class=\"absolute top-6 px-6 w-full\" data-v-bc83a99a><div class=\"flex flex-row items-center justify-between\" data-v-bc83a99a><div class=\"text-2xl md:text-4xl text-white font-bold font-cormorant-garamond\" data-v-bc83a99a> Morgan Oasis Nails </div><div class=\"flex space-x-2 md:space-x-6\" data-v-bc83a99a><a href=\"https://www.facebook.com/profile.php?id=100054221360821\" data-v-bc83a99a><img src=\"" + _imports_2 + "\" class=\"h-6 md:h-9 mt-1 inverted\" alt=\"\" data-v-bc83a99a></a><a href=\"https://www.instagram.com/morganoasisnails_2020/\" data-v-bc83a99a><img src=\"" + _imports_3 + "\" class=\"h-8 md:h-12 inverted\" alt=\"\" data-v-bc83a99a></a></div></div></div><div class=\"absolute top-16 md:top-22 px-6 text-white text-sm md:text-lg w-screen flex flex-col\" data-v-bc83a99a><div data-v-bc83a99a> Monday - Saturday: 9:30 AM - 7:00 PM<br data-v-bc83a99a> Sunday: Close<br data-v-bc83a99a> (405) 265-2494<br data-v-bc83a99a> 1719 S Morgan Rd, Oklahoma City, OK 73128 </div></div><div class=\"absolute flex flex-col bottom-6 justify-between w-screen px-6 text-sm\" data-v-bc83a99a><div class=\"flex flex-col\" data-v-bc83a99a><a href=\"https://3amlab.io\" class=\"flex flex-row items-center\" data-v-bc83a99a><img src=\"" + _imports_4 + "\" class=\"h-8 mr-2\" alt=\"\" data-v-bc83a99a><div class=\"flex flex-col items-start text-white text-[12px]\" data-v-bc83a99a><span class=\"\" data-v-bc83a99a>Powered by</span><span class=\"text-[12px] font-mono\" data-v-bc83a99a>3AMLAB.IO</span></div></a><div class=\"flex flex-col\" data-v-bc83a99a><div class=\"text-white text-sm md:text-lg\" data-v-bc83a99a> Copyright © 2022, All Rights Reserved </div></div></div></div></div>", 1)
  ])))
}